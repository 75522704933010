import './App.css';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import logo from './grafiame.png';
import { useGlobalState, setName } from './state';
import {useSessionStorage} from "react-use-storage";
import {
	isBrowser,
	isMobile
  } from "react-device-detect";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import baseURL from './Common';

function NewAccount() {
	const [data, setData] = useSessionStorage("data");
	const [isBackdrop, setIsBackdrop] = useState(false);
	const [showSnack,setShowSnack] = useState(false);
	const [snack, setSnack] = useState({
		message: "",
		show: false,
		vertical: 'top',
		horizontal: 'right',
	});

	const months = [
		{
			value: '01',
			label: 'Janeiro'
		},
		{
			value: '02',
			label: 'Fevereiro'
		},
		{
			value: '03',
			label: 'Março'
		},
		{
			value: '04',
			label: 'Abril'
		},
		{
			value: '05',
			label: 'Maio'
		},
		{
			value: '06',
			label: 'Junho'
		},
		{
			value: '07',
			label: 'Julho'
		},
		{
			value: '08',
			label: 'Agosto'
		},
		{
			value: '09',
			label: 'Setembro'
		},
		{
			value: '10',
			label: 'Outubro'
		},
		{
			value: '11',
			label: 'Novembro'
		},
		{
			value: '12',
			label: 'Dezembro'
		},
	];

	const genders = [
		{
			label: 'Feminino'
		},
		{
			label: 'Masculino'
		},
		{
			label: 'Prefiro não dizer'
		},
	];

	useEffect(() => {
		if (!data.invite){
			var newSnack = snack;
			newSnack.message = "Convite inválido! Entre em contato com a sua organização";
			newSnack.show = true;
			setSnack(newSnack);
			setShowSnack(true);
			setTimeout(function () {
				document.location = document.location.origin + '/start';
			}, 5000)
		}
	}, []);

	function setInfo(prop, value){
		var v = data;
		v[prop] = value;
		setData(v);
	}

	function checkValid() {
		var result = !(data.password == data.passwordConfirmation) || !(data.password && data.passwordConfirmation);
		result = result || !data.name || !data.lastName || !data.day || !data.month || !data.year || !data.email;
		return result;
	}

	function checkValidPwd() {
		var result = !(data.password == data.passwordConfirmation) || !(data.password && data.passwordConfirmation);
		return result;
	}

	function createAccount() {
		setIsBackdrop(true);
		var xhr = new XMLHttpRequest();
			xhr.open('POST', baseURL+'u/create?invite='+data.invite, true);
			xhr.addEventListener("readystatechange", function () {
				if (this.readyState === this.DONE) {
					//setIsBackdrop(false);
					if (this.status == 200){
						var response = JSON.parse(xhr.responseText);
						if (response){
							setInfo('uid', response.uid);
							document.location = document.location.origin + '/verifyEmail';							
						}
					} else {
						//Erro
						setIsBackdrop(false);
						var error = JSON.parse(xhr.responseText);
						var newSnack = snack;
						newSnack.message = error.message;
						newSnack.show = true;
						setSnack(newSnack);
						setShowSnack(true);
					}

				}
			});
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(JSON.stringify({ 
				firstName: data.name,
				lastName: data.lastName,
				email: data.email,
				password: data.password,
				day: data.day,
				month: data.month,
				year: data.year,
				gender: data.gender,
			}));
		
	}

	var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
	(function(){
	var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
	s1.async=true;
	s1.src='https://embed.tawk.to/5fed3ac3df060f156a927fe1/1eqrar3h9';
	s1.charset='UTF-8';
	s1.setAttribute('crossorigin','*');
	s0.parentNode.insertBefore(s1,s0);
	})();

  return (
    <div style={{width: '100vw', height: '100vh', backgroundColor: 'rgb(216,216,216)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
		<Backdrop open={isBackdrop} style={{zIndex: 999, color: '#fff'}}>
			<CircularProgress color="inherit" />
		</Backdrop>

		<Snackbar
			anchorOrigin={{ vertical: snack.vertical, horizontal: snack.horizontal }}
			open={showSnack}
			onClose={() => setShowSnack(false)}
			message={snack.message}
			key={snack.vertical + snack.horizontal}
			style={{zIndex: 1000}}
			autoHideDuration={5000}
		/>

		<Paper style={{width: '40%', height: '600px', backgroundColor: 'white', display: 'flex', minWidth: '400px' }} elevation={3}>
			<div style={{width: (isMobile || true ? '100%' : '60%'), paddingLeft: '20px', paddingRight: '20px', margin: '15px'}}>
				<div style={{width: '100%', height: '100px', textAlign: 'center', marginTop: '20px'}}>
					<img src={logo} style={{width: '130px'}} />
					<Typography variant="h5" gutterBottom style={{paddingTop: '30px'}}>
						Criar sua conta da Grafiame
					</Typography>
				</div>
				<div style={{width: '100%', height: '100px', marginTop: '40px'}}>
					<div style={{display: 'flex'}}>
						<TextField
							id="standard-read-only-input"
							label="Nome"
							style={{flex: '0.5'}}
							variant="outlined"
							size="small"
							value={data.name}
							onChange={(ev) => setInfo('name', ev.target.value)}
						/>
						<TextField
							id="standard-read-only-input"
							label="Sobrenome"
							style={{flex: '0.5', marginLeft: '20px'}}
							variant="outlined"
							size="small"
							value={data.lastName}
							onChange={(ev) => setInfo('lastName', ev.target.value)}
						/>
					</div>
					<TextField
						id="standard-read-only-input"
						label="Email"
						style={{width: '100%', marginTop: '20px'}}
						variant="outlined"
						size="small"
						value={data.email}
						onChange={(ev) => setInfo('email', ev.target.value)}
					/>
					<div style={{marginTop: '10px', color: '#565656'}}>
						<Typography variant="subtitle1" gutterBottom>
							Data de nascimento
						</Typography>
					</div>
					
					<div style={{display: 'flex'}}>
						<TextField
							id="standard-read-only-input"
							label="Dia"
							variant="outlined"
							size="small"
							value={data.day}
							type="number"
							style={{flex: '0.3'}}
							onChange={(ev) => setInfo('day', ev.target.value)}
						/>
						<TextField
							id="standard-read-only-input"
							label="Mês"
							select
							variant="outlined"
							size="small"
							value={data.month}
							style={{flex: '0.4', marginLeft: '20px'}}
							onChange={(ev) => setInfo('month', ev.target.value)}
						>
							{months.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<TextField
							id="standard-read-only-input"
							label="Ano"
							variant="outlined"
							size="small"
							type="number"
							value={data.year}
							style={{flex: '0.3', marginLeft: '20px'}}
							onChange={(ev) => setInfo('year', ev.target.value)}
						/>
					</div>
					<TextField
						id="standard-read-only-input"
						label="Gênero"
						select
						variant="outlined"
						size="small"
						value={data.gender}
						style={{width: '100%', marginTop: '20px'}}
						onChange={(ev) => setInfo('gender', ev.target.value)}
					>
						{genders.map((option) => (
							<MenuItem key={option.label} value={option.label}>
							{option.label}
							</MenuItem>
						))}
					</TextField>

					<div style={{marginTop: '10px', color: '#565656'}}>
						<Typography variant="subtitle1" gutterBottom>
							Crie uma senha
						</Typography>
					</div>

					<div style={{marginTop: '5px', display: 'flex'}}>
						
						<TextField
							id="standard-read-only-input"
							label="Senha"
							style={{flex: '0.5'}}
							variant="outlined"
							size="small"
							type="password"
							value={data.password}
							onChange={(ev) => setInfo('password', ev.target.value)}
							error={checkValidPwd()}
						/>
						<TextField
							id="standard-read-only-input"
							label="Confirmar"
							style={{flex: '0.5', marginLeft: '20px'}}
							variant="outlined"
							size="small"
							type="password"
							value={data.passwordConfirmation}
							error={checkValidPwd()}
							onChange={(ev) => setInfo('passwordConfirmation', ev.target.value)}
						/>
					</div>
					<div style={{marginTop: '50px'}}>
						{//<Link href="#" style={{paddingTop: '20px', fontWeight: 500}}>
							//Fazer login em vez disso
						//</Link>
						}
						<div style={{float: 'right', marginRight: '20px'}}>
							<Button variant="contained" disabled={checkValid()} color="primary" onClick={createAccount}>
								Próxima
							</Button>
						</div>
						
					</div>

				</div>
			</div>


			{isMobile || true ? <div/> : 			
				<div style={{width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<span>ilustração</span>
				</div>
			}
				
		</Paper>			
    </div>
  );
}

export default NewAccount;
