import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Start from './Start';
import NewAccount from './NewAccount';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { createGlobalState } from 'react-hooks-global-state';
import VerifyEmail from './verifyEmail';
import AcceptTerms from './AcceptTerms';
import Success from './Success';


ReactDOM.render(
  <React.StrictMode>
    {
      
    }
    <Router>
      <Switch>

        <Route exact path="/" >
			    <Redirect to={document.location.href.split('?').length > 1 ? "/"+document.location.href.split('?')[1] : "/start"} />
        </Route>

        <Route path="/invite=:invite" children={<Start/>}>
          
        </Route>
        

        <Route path="/start">
          <Start/>
        </Route>

        <Route path="/newAccount">
          <NewAccount />
        </Route>

        <Route path="/verifyEmail">
          <VerifyEmail/>
        </Route>

		    <Route path="/acceptTerms">
          <AcceptTerms/>
        </Route>

        <Route path="/success">
          <Success/>
        </Route>
        
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
