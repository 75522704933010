import logo from './logo.svg';
import './App.css';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {useSessionStorage} from "react-use-storage";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";

function Success() {
  const initialData = { 
		invite: '',
		uid: '',
		name: '',
		lastName: '',
		email: '',
		password: '',
		passwordConfirmation: '',
		day: '',
		month: '',
		year: '',
		gender: '',
		termsAccepted1: false,
		termsAccepted2: false,
	};
  const [data, setData] = useSessionStorage("data");

  useEffect(() => {
    setData(initialData);
  },[])
	
  return (
    <div style={{width: '100vw', height: '100vh', backgroundColor: 'rgb(216,216,216)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
		<Paper style={{width: '70%', height: '80%', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} elevation={0}>
			<span style={{fontSize: '30px', fontWeight: 700, color: 'green'}}>Conta criada com sucesso!</span>	
		</Paper>			
    </div>
  );
}

export default Success;
