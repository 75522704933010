import logo from './grafiame.png';
import './App.css';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useSessionStorage} from "react-use-storage";
import {
	isBrowser,
	isMobile
  } from "react-device-detect";


function AcceptTerms(props) {
	const [data, setData] = useSessionStorage("data");
	const [isBackdrop, setIsBackdrop] = useState(false);

	function createAccount() {
		setIsBackdrop(true);
		var xhr = new XMLHttpRequest();
			xhr.open('POST', 'https://run.mocky.io/v3/74b8297b-412d-4cb4-a13d-bd8363e74166', true);
			xhr.addEventListener("readystatechange", function () {
				if (this.readyState === this.DONE) {
					setIsBackdrop(false);
					if (this.status == 200){
						document.location = document.location.origin + '/success';
					} else {
						//Erro???
					}
				}
			});
			xhr.send({
				uid: data.uid,
			});
	}

	function setInfo(prop, value){
		var v = data;
		v[prop] = value;
		setData(v);
	}

  return (
    <div style={{width: '100vw', height: '100vh', backgroundColor: 'rgb(216,216,216)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
		<Paper style={{width: (isMobile || true? '100%' : '40%'), height: '600px', backgroundColor: 'white', display: 'flex' }} elevation={3}>
			<div style={{width: (isMobile || true? '100%' : '60%'), paddingLeft: '30px'}}>
				<div style={{width: '100%', height: '100px', textAlign: 'center', marginTop: '20px'}}>
					<img src={logo} style={{width: '100px'}} />
					<Typography variant="h5" gutterBottom style={{paddingTop: '30px'}}>
						Privacidade e termos
					</Typography>
				</div>
				<div style={{width: '100%', height: '100px', marginTop: '20px', fontWeight: 600}}>
					<Typography variant="subtitle1" gutterBottom>
						Para criar uma conta Grafiame, você precisa concordar com os termos de serviço abaixo.
						Além disso, quando você cria uma conta, nós processamos seus dados pessoais (conforme descrito na nossa política de privacidade).
					</Typography>
					<div style={{textAlign: 'center', marginTop: '30px'}}>
						<Link href="#" style={{paddingTop: '20px', fontWeight: 500, fontSize: '22px'}}>
							Termos de Serviço
						</Link>
					</div>
					
					<div style={{textAlign: 'center', marginTop: '30px'}}>
						<Link href="#" style={{paddingTop: '20px', fontWeight: 500, fontSize: '22px'}}>
							Política de Privacidade
						</Link>
					</div>
					<div style={{marginTop: '30px'}}>
						<FormControlLabel
							control={<Checkbox checked={data.termsAccepted1} onChange={(ev) => setInfo('termsAccepted1', ev.target.checked)} name="checkedA" />}
							label="Concordo com os termos e serviços da Grafiame"
							color="primary"
						/>
					</div>
					<div>
						<FormControlLabel
							control={<Checkbox checked={data.termsAccepted2} onChange={(ev) => setInfo('termsAccepted2', ev.target.checked)} name="checkedA" />}
							label="Concordo com a política de privacidade da Grafiame"
							color="primary"
						/>
					</div>
					<div style={{float: 'right', margin: '20px'}}>
						<Button variant="contained" color="primary" onClick={createAccount} disabled={!data.termsAccepted2 || !data.termsAccepted1}>
							Criar conta!
						</Button>
					</div>
				</div>
				
				
			</div>


			{isMobile || true ? <div/> :
				<div style={{width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<span>ilustração</span>
				</div>
			}
				
		</Paper>			
    </div>
  );
}

export default AcceptTerms;
