import logo from './logo.svg';
import './App.css';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {useSessionStorage} from "react-use-storage";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import baseURL from './Common';
import {
	isBrowser,
	isMobile
} from "react-device-detect";

function Start() {
	const initialData = { 
		invite: '',
		uid: '',
		name: '',
		lastName: '',
		email: '',
		password: '',
		passwordConfirmation: '',
		day: '',
		month: '',
		year: '',
		gender: '',
		termsAccepted1: false,
		termsAccepted2: false,
	};
	const [data, setData] = useSessionStorage("data",  initialData);

	const [isBackdrop, setIsBackdrop] = useState(true);
	const [showSnack,setShowSnack] = useState(false);
	const [snack, setSnack] = useState({
		message: '',
		vertical: 'top',
		horizontal: 'right',
	});
	let { invite } = useParams();

	useEffect(() => {
		setData(initialData)
		var inviteData = {};
		if (invite){
			//Passar invite no request
			var xhr = new XMLHttpRequest();
			
			xhr.open('GET', baseURL+'o/getInvite?&invite='+invite, true);
			xhr.addEventListener("readystatechange", function () {
				if (this.readyState === this.DONE) {
					
					if (this.status == 200){
						setIsBackdrop(false);
						//Sucesso
						inviteData = JSON.parse(xhr.responseText);
						var v = data;
						v.invite = invite;
						if (inviteData && inviteData){
							var userData = inviteData.user;
							if (userData){
								var date = userData.birthday.split('/');
								data.name = userData.firstName;
								data.lastName = userData.lastName;
								data.email = userData.email;
								if (date.length > 0) {
									data.day = date[0];
									data.month = date[1];
									data.year = date[2];
								}
								data.gender = userData.gender;
							}
							setData(v);
							
							document.location = document.location.origin + '/newAccount'
						}
					} else {
						//Erro
						setIsBackdrop(false);
						var error = JSON.parse(xhr.responseText);
						var newSnack = snack;
						newSnack.message = error.message;
						
						setSnack(newSnack);
						setShowSnack(true);
					}
				}
			});
			xhr.send(null);
		} else {
			setIsBackdrop(false);
		}
	}, []);

	var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
	(function(){
	var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
	s1.async=true;
	s1.src='https://embed.tawk.to/5fed3ac3df060f156a927fe1/1eqrar3h9';
	s1.charset='UTF-8';
	s1.setAttribute('crossorigin','*');
	s0.parentNode.insertBefore(s1,s0);
	})();

	var browserVersion = (
		<div style={{width: '100vw', height: '100vh', backgroundColor: 'rgb(216,216,216)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<Backdrop open={isBackdrop} style={{zIndex: 999, color: '#fff'}} >
				<CircularProgress color="inherit" />
			</Backdrop>

			

			<Snackbar
				anchorOrigin={{ vertical: snack.vertical, horizontal: snack.horizontal }}
				open={showSnack}
				onClose={() => setShowSnack(false)}
				message={snack.message}
				key={snack.vertical + snack.horizontal}
				
			/>

			<Paper style={{width: '70%', height: '80%', backgroundColor: 'white', display: 'flex' }} elevation={0}>

				{/*<div style={{height: '100%', width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
					<Paper style={{width: '400px', height: '400px', backgroundColor: 'white', borderRadius: '20px', textAlign: 'center', cursor: 'pointer' }} elevation={3}>
						<div style={{marginTop: '100px', marginLeft: '35px', marginRight: '35px'}}>
							<Typography variant="h2" gutterBottom>
								Entrar
							</Typography>
							<Typography variant="h6" gutterBottom>
								Caso você já possua uma conta Grafiame e queira fazer login
							</Typography>
						</div>
						
					</Paper>
	</div>*/}

				<div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
					<Paper style={{width: '400px', height: '400px', backgroundColor: 'white', borderRadius: '20px', textAlign: 'center' }} elevation={3}>
						<div style={{marginTop: '100px', marginLeft: '35px', marginRight: '35px'}} onClick={() => {document.location = document.location.origin + '/newAccount'}}>
							<Typography variant="h2" gutterBottom>
								Criar conta
							</Typography>
							<Typography variant="h6" gutterBottom>
								Caso você seja nov@ por aqui e queira criar uma conta - Seja bem vind@!
							</Typography>
						</div>
						
					</Paper>
				</div>
					
			</Paper>			
		</div> 
	);

	var mobileVersion = (
		<div style={{width: '100vw', height: '100vh', backgroundColor: 'rgb(216,216,216)', alignItems: 'center', justifyContent: 'center'}}>
			<Backdrop open={isBackdrop} style={{zIndex: 999, color: '#fff'}}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Snackbar
				anchorOrigin={{ vertical: snack.vertical, horizontal: snack.horizontal }}
				open={showSnack}
				onClose={() => setShowSnack(false)}
				message={snack.message}
				key={snack.vertical + snack.horizontal}
			/>

			{/*<div style={{height: '50%', width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto' }}>
				<Paper style={{width: '95%', height: '40vh', backgroundColor: 'white', borderRadius: '20px', textAlign: 'center', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} elevation={3}>
					<div style={{ marginLeft: '35px', marginRight: '35px'}}>
						<Typography variant="h3" gutterBottom>
							Entrar
						</Typography>
						<Typography variant="h6" gutterBottom>
							Caso você já possua uma conta Grafiame e queira fazer login
						</Typography>
					</div>
					
				</Paper>
	</div>*/}

			<div style={{height: '100%', width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto'}}>
				<Paper style={{width: '95%', height: '40vh', backgroundColor: 'white', borderRadius: '20px', textAlign: 'center', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center'}} elevation={3}>
					<div style={{ marginLeft: '35px', marginRight: '35px'}} onClick={() => {document.location = document.location.origin + '/newAccount'}}>
						<Typography variant="h3" gutterBottom>
							Criar conta
						</Typography>
						<Typography variant="h6" gutterBottom>
							Caso você seja nov@ por aqui e queira criar uma conta - Seja bem vind@!
						</Typography>
					</div>
					
				</Paper>
			</div>
					
		</div> 
	);

  return (
	  <div>
		  {isBrowser ? browserVersion : mobileVersion}
	  </div>
  );
}

export default Start;
