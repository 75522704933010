import './App.css';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {useState} from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import logo from './grafiame.png';
import {
	isBrowser,
	isMobile
} from "react-device-detect";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSessionStorage} from "react-use-storage";
import Snackbar from '@material-ui/core/Snackbar';
import baseURL from './Common';

function VerifyEmail() {
	const [code, setCode] = useState('');
	const [data, setData] = useSessionStorage("data");
	const [isBackdrop, setIsBackdrop] = useState(false);
	const [showSnack,setShowSnack] = useState(false);
	const [snack, setSnack] = useState({
		message: '',
		vertical: 'top',
		horizontal: 'right',
	});
	
	useState(() => {
		sendMail();
	}, [])

	function verifyEmail() {
		setIsBackdrop(true);
		var xhr = new XMLHttpRequest();
			xhr.open('POST', baseURL+'u/'+data.uid+'/registration?code='+code, true);
			xhr.addEventListener("readystatechange", function () {
				if (this.readyState === this.DONE) {
					setIsBackdrop(false);
					if (this.status == 200){
						document.location = document.location.origin + '/success';
					} else {
						//Erro
						setIsBackdrop(false);
						var error = JSON.parse(xhr.responseText);
						var newSnack = snack;
						newSnack.message = error.message;
						
						setSnack(newSnack);
						setShowSnack(true);
					}
				}
			});
			xhr.send();
	}

	function openLink() {
		var win = window.open("https://sites.google.com/view/polticagrafiame/", '_blank');
		win.focus();
	}

	function sendMail() {
		var xhr2 = new XMLHttpRequest();
			xhr2.open('POST', baseURL+'u/'+data.uid+'/verifyEmail', true);
			xhr2.setRequestHeader("Content-Type", "application/json");
			xhr2.addEventListener("readystatechange", function () {
				if (this.readyState === this.DONE) {
					setIsBackdrop(false);
					if (this.status != 200){
						//Erro
						setIsBackdrop(false);
						var error = JSON.parse(xhr2.responseText);
						var newSnack = snack;
						newSnack.message = error.message;
						
						setSnack(newSnack);
					}
				}
			});
			xhr2.send(JSON.stringify({
				uid: data.uid,
			}));
	}

	var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
	(function(){
	var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
	s1.async=true;
	s1.src='https://embed.tawk.to/5fed3ac3df060f156a927fe1/1eqrar3h9';
	s1.charset='UTF-8';
	s1.setAttribute('crossorigin','*');
	s0.parentNode.insertBefore(s1,s0);
	})();

  return (
    <div style={{width: '100vw', height: '100vh', backgroundColor: 'rgb(216,216,216)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
		<Backdrop open={isBackdrop} style={{zIndex: 999, color: '#fff'}}>
			<CircularProgress color="inherit" />
		</Backdrop>

		<Snackbar
			anchorOrigin={{ vertical: snack.vertical, horizontal: snack.horizontal }}
			open={showSnack}
			onClose={() => setShowSnack(false)}
			message={snack.message}
			key={snack.vertical + snack.horizontal}
		/>

		<Paper style={{width: (isMobile? '100%' : '30%'), height: '600px', backgroundColor: 'white', minWidth: '400px'}} elevation={3}>
			<div style={{margin: '30px'}}>
				<div style={{height: '200px', textAlign: 'center'}}>
					<img src={logo} style={{width: '200px', marginBottom: '60px'}} />
					<Typography variant="h4" gutterBottom>
						Confirme seu email
					</Typography>
				</div>
				<div style={{height: '75px', textAlign: 'center'}}>
					<Typography variant="subtitle1" gutterBottom>
						Para verificar sua conta, insira o código de 7 dígitos que enviamos para seu email
					</Typography>
				</div>
				<div style={{textAlign: 'center', marginTop: '0px'}}>
					<div style={{justifyContent: 'center', display: 'flex'}}>
						<TextField
							id="standard-read-only-input"
							label="Código"
							style={{width: '500px', marginTop: '20px'}}
							variant="outlined"
							value={code}
							onChange={(ev) => setCode(ev.target.value)}
							
						/>
					</div>
					<Link href="#" onClick={sendMail}>
						Não recebeu o email? Reenviar
					</Link>
					<div style={{marginTop: '20px'}}>
						<Typography variant="subtitle1" gutterBottom>
							(Verifique também sua caixa de spam!)
						</Typography>
					</div>

					<div style={{marginTop: '30px'}}>
						<Typography variant="subtitle2" gutterBottom>
							Ao criar uma conta na Grafiame, você concorda com nossa política de privacidade
						</Typography>
					</div>

				</div>
				<div style={{width: '100%', height: '100px', paddingTop: '20px'}}>
					<div style={{float: 'left', marginLeft: '20px'}}>
						<Link href="#" onClick={openLink} style={{ fontWeight: 500, fontSize: '16px'}}>
							Política de Privacidade
						</Link>
					</div>
					<div style={{float: 'right', marginRight: '20px'}}>
						<Button variant="contained" color="primary" disabled={!code || code.length < 6} onClick={verifyEmail}>
							Próxima
						</Button>
					</div>
				</div>
			</div>
		</Paper>			
    </div>
  );
}

export default VerifyEmail;
